import React, { useEffect } from "react"
import { navigate } from "gatsby"

const IndexPage = () => {
  useEffect(() => {
    // Adding forced redirection for old link compatibility.
    navigate(
      "/resources/blog/distributed-clouds-with-a-centralized-mission-the-volterra-story"
    )
  }, [])

  return <></>
}

export default IndexPage
